@import '~booksprout-app/src/css/functions/rem-fn'

.bs-login-instead
  align-items: center
  gap: rem-fn(5)
  justify-content: center
  margin-block-start: rem-fn(30)

  .bs-btn
    font-size: rem-fn(15)
