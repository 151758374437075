@use "~booksprout-app/src/css/quasar.variables.sass" as *
@use "~booksprout-app/src/css/mixins/media.sass" as *
@import '../css/functions/rem-fn'

.bs-modal .q-dialog__inner > .termly-dialog
  text-align: left

  > div
    margin-inline: auto
    max-inline-size: rem-fn(1200)

  a
    color: $bs-green

  button[role=link]
    appearance: none
    background-color: transparent
    border: none
    color: $bs-green
    cursor: pointer
    padding: 0

    &:hover,
    &:focus
      text-decoration: underline
